import { WithSidebarMenuLayout } from "components/routing/page/layouts/with-sidebar-menu/with-sidebar-menu-layout";
import MaintenanceTemplate from "pages/maintenance";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getAudiences } from "store/modules/audiences/actions/index";
import { getProblems } from "store/modules/problems/actions/index";
import { getSolutions } from "store/modules/solutions/actions/index";
import { setProjectSettings } from "store/modules/projects/actions";
import { ProjectSettings } from "store/modules/projects/types";
import { LoadingStatus } from "store/types";
import { LoginModal } from "templates/index/login";
import { WorkspacePage } from "../workspace-page/workspace-page";
import { useBetaUI } from "logic/hooks/betaUI";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useImpersonateLogin } from "logic/hooks/impersonate-login";

type IndexContainerProps = {
  isMaintenance: boolean;
  initialBetaUIFeatureFlagEnabled?: boolean;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
};

export const IndexContainer: React.FC<IndexContainerProps> = ({
  isMaintenance,
  initialBetaUIFeatureFlagEnabled,
  ragEnabled,
  surveysEnabled,
}) => {
  function Content() {
    const dispatch = useAppDispatch();
    const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
    const projectId = useAppSelector((state) => state.projects.data.project?.id);
    const projectSettings = useAppSelector((state) => state.projects.data.projectSettings);
    const audiences = useAppSelector((state) => state.audiences);
    const problems = useAppSelector((state) => state.problems);
    const solutions = useAppSelector((state) => state.solutions);

    const { getBetaUIEnabled } = useBetaUI();
    const { ongoingWalkthrough, markWalkthroughAsDone } = useOngoingWalkthrough();
    const { isImpersonating } = useImpersonateLogin();

    /***** Handle initial project settings *****/
    useEffect(() => {
      // Early return if project settings are already set
      if (projectSettings) return;
      // Set default workspace settings if not Beta UI enabled (Problem Exploration Mode)
      if (getBetaUIEnabled() === false) {
        dispatch(setProjectSettings(ProjectSettings.ProblemExplorationMode));
      }
    }, [dispatch, projectSettings, getBetaUIEnabled]);

    /***** Handle ongoing walkthrough *****/
    useEffect(() => {
      // Early return if no workspace mode
      if (!workspaceMode) return;
      // Mark walkthrough as done if not in Anonymous User mode
      if (workspaceMode !== WorkspaceMode.AnonymousUser && ongoingWalkthrough) {
        markWalkthroughAsDone();
      }
    }, [ongoingWalkthrough, markWalkthroughAsDone, workspaceMode]);

    /***** Handle initial audiences data *****/
    useEffect(() => {
      // Early return if projectId is not set or audiences are already loaded, loading or errored
      if (!projectId || audiences.loading !== LoadingStatus.none || audiences.error) return;
      // Get audiences
      if (!audiences.data.audiences) {
        dispatch(getAudiences({ projectId: projectId }));
        return;
      }
    }, [projectId, dispatch, audiences]);

    /***** Handle initial problems data *****/
    useEffect(() => {
      // Early return if projectId is not set or problems are already loaded, loading or errored
      if (!projectId || problems.loading !== LoadingStatus.none || problems.error) return;
      // Get problems
      if (!problems.data.problems) {
        dispatch(getProblems({ projectId: projectId }));
        return;
      }
    }, [projectId, dispatch, problems]);

    /***** Handle initial solutions data *****/
    useEffect(() => {
      // Early return if projectId is not set or problems are already loaded, loading or errored
      if (!projectId || solutions.loading !== LoadingStatus.none || solutions.error) return;
      // Get solutions
      if (!solutions.data.solutions) {
        dispatch(getSolutions({ projectId: projectId }));
        return;
      }
    }, [projectId, dispatch, solutions]);

    return (
      <WithSidebarMenuLayout
        initialBetaUIFeatureFlagEnabled={initialBetaUIFeatureFlagEnabled}
        ragEnabled={ragEnabled}
        surveysEnabled={surveysEnabled}
        isImpersonating={isImpersonating}
      >
        <WorkspacePage />
      </WithSidebarMenuLayout>
    );
  }

  return (
    <>
      {isMaintenance ? (
        <MaintenanceTemplate />
      ) : (
        <>
          <Content />
          <LoginModal />
        </>
      )}
    </>
  );
};
